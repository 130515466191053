import { createTheme } from '@mui/material/styles';
import { red,grey } from '@mui/material/colors';
import {frFR} from '@mui/material/locale'

// Create a theme instance.
const theme =  createTheme({
  palette: {
    primary: {
      light: red[400],
      main: '#ED1C24',
      dark: red[600]
    },
    secondary: {
      light: "#263d70",
      main: "#1D2F56",
      dark: "#162443"
    },
    success: {
      main: '#16B438'
    },
    type: 'light',
  },
  background: {
    default: '#fff'
  },
  typography: {
    fontFamily: [
      'Lato',
      'Montserrat',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
}, frFR);

export default theme;
