import * as React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import theme from '../src/theme';
import createEmotionCache from '../src/createEmotionCache';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';
import '../components/skeleton/skeleton-red-cross/skeleton-red-cross.css'

// Client-side cache, shared for the whole session of the candidate in the browser.
const clientSideEmotionCache = createEmotionCache();

const MyApp = (props)=> {
  const { Component, emotionCache = clientSideEmotionCache,pageProps: { session, ...pageProps }} = props;
  const getLayout = Component.getLayout || ((page) => page)

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        {
              process.env.NEXT_PUBLIC_DOPPLER_CONFIG ==="dev" &&
              <meta name="robots" content="noindex,nofollow" />
        }
      </Head>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
          <SessionProvider session={session}>
              {getLayout(<Component {...pageProps} />)}
          </SessionProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};

export default appWithTranslation(MyApp);
